import App from "@/app";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { type FC, useEffect, useState } from "react";
import { LoadingSpinner } from "./components/LoadingSpinner";
import { env } from "./config/env";

const ErrorBoundaryError: FC<{ error: unknown }> = ({ error }) => (
  <div className="text-white w-[90%] h-screen flex flex-col items-center justify-center max-w-[90%] mx-auto">
    <img src="/logo.svg" alt="logo" className="h-10" />
    <h2 className="text-xl font-bold mt-4 mb-2">An unhandled error occurred:</h2>
    <code className="bg-gray-800 p-2 rounded-md mt-2">
      {error instanceof Error ? error.message : typeof error === "string" ? error : JSON.stringify(error)}
    </code>
  </div>
);

const Inner: FC = () => {
  const [initialized, setInitialized] = useState(false);
  const debug = env.DEV;

  useEffect(() => {
    if (debug) {
      import("eruda").then((lib) => {
        lib.default.init();
        setInitialized(true);
      });
    } else {
      setInitialized(true);
    }
  }, [debug]);

  if (!initialized) {
    return <LoadingSpinner />;
  }

  return <App />;
};

export const Root: FC = () => (
  <ErrorBoundary fallback={ErrorBoundaryError}>
    <Inner />
  </ErrorBoundary>
);
