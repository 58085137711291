import { MobileLayout } from "@/components/mobile";
import { PCLayout } from "@/components/pc";
import { useMediaQuery } from "@uidotdev/usehooks";
import ReactGA from "react-ga4";

export const HomePage = () => {
  ReactGA.send({ hitType: "pageview", page: "/" });

  const isMobile = useMediaQuery("only screen and (max-width : 768px)");

  return <div>{isMobile ? <MobileLayout /> : <PCLayout />}</div>;
};
