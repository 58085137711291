import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { cn } from "@/utils";
import { useInView } from "react-intersection-observer";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { StepComponent, steps } from "../pc/HowToPlay";
import { MainButton } from "../shared/MainButton";

export const HowToPlay = () => {
  const pagination = {
    clickable: true,
    renderBullet: (_: number, className: string) => {
      return `<span class="${className}"></span>`;
    },
  };

  const { ref, inView } = useInView({
    threshold: 0.3,
    delay: 500,
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      <div className="w-full h-full text-center">
        <p className={cn("text-[#B2B2B2] text-[14px] leading-[14px] fadeInUp", inView && "fadeInUp-active")}>
          How To Play
        </p>
        <p
          className={cn("text-white text-[32px] leading-[40px] fadeInUp", inView && "fadeInUp-active")}
          style={{ animationDelay: "0.2s" }}
        >
          What is thumba?
        </p>
      </div>

      <div className="flex flex-col items-center mt-[30px] h-[513px]">
        <Swiper
          navigation={true}
          pagination={pagination}
          modules={[Pagination, Navigation]}
          loop={false}
          className={cn("w-full h-full py-[30px] fadeInUp", inView && "fadeInUp-active")}
          style={{ animationDelay: "0.4s" }}
        >
          {steps.map((step) => (
            <SwiperSlide key={step.id} className="text-center">
              <StepComponent step={step} className="h-[322px]" />
            </SwiperSlide>
          ))}
        </Swiper>

        <a
          href="https://t.me/ThumbaGameBot"
          className={cn("fadeInUp", inView && "fadeInUp-active")}
          style={{ animationDelay: "0.2s" }}
        >
          <MainButton className="w-[270px]">Play Now</MainButton>
        </a>
      </div>
    </div>
  );
};
