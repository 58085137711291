import { env } from "@/config/env";
import axios from "axios";
import { useEffect, useState } from "react";

interface UserCountRes {
  count: number;
}

export const useUserCount = () => {
  const [userCount, setUserCount] = useState<number | null>(null);

  useEffect(() => {
    const init = async () => {
      const { API_ENDPOINT } = env;
      const res = await axios.get<UserCountRes>(`${API_ENDPOINT}/user/count`);
      const { count } = res.data;

      setUserCount(count);
    };

    init();
  }, []);

  return {
    userCount,
  };
};
