import { env } from "@/config/env";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";

export interface CampaignStep {
  step1?: string;
  step2?: string;
  step3?: string;
  step4?: string;
  invitationCode: string;
  currentStep: 0 | 1 | 2 | 3 | 4;
}

interface UserCampaignRes {
  user: {
    Step1: string;
    Step2: string;
    Step3: string;
    Step4: string;
    InvitationCode: string;
  };
}

export const useUserCampaign = (telegramUserId: number | undefined) => {
  const [userCampaign, setUserCampaign] = useState<CampaignStep | null>(null);

  const currentStep = useMemo(() => {
    if (userCampaign?.step4) return 4;
    if (userCampaign?.step3) return 3;
    if (userCampaign?.step2) return 2;
    if (userCampaign?.step1) return 1;

    return 0;
  }, [userCampaign]);

  useEffect(() => {
    const init = async () => {
      const { API_ENDPOINT } = env;
      const res = await axios.get<UserCampaignRes>(`${API_ENDPOINT}/user/campaign?telegramUserId=${telegramUserId}`);
      const { user } = res.data;

      console.log("user", user);

      setUserCampaign({
        step1: user?.Step1,
        step2: user?.Step2,
        step3: user?.Step3,
        step4: user?.Step4,
        invitationCode: user?.InvitationCode,
        currentStep,
      });
    };

    init();
  }, [telegramUserId, currentStep]);

  return {
    userCampaign,
  };
};
