import { SocialIcon } from "../pc/Header";

export const Footer = () => (
  <div className="flex flex-col items-center pt-[20px] pb-[60px] md:hidden">
    <div className="flex flex-raw justify-around mb-[20px] gap-4">
      <p className="text-gradient text-[18px] leading-[24px]">
        <span className="text-[#989898]">©</span>DraftBeasts Inc.
      </p>

      <a className="text-[#989898] text-[14px] leading-[24px] hover:link-gradient" href="/privacy-policy">
        privacy policy
      </a>
      <a className="text-[#989898] text-[14px] leading-[24px] hover:link-gradient" href="/aml-policy">
        AML policy
      </a>
      <a className="text-[#989898] text-[14px] leading-[24px] hover:link-gradient" href="/terms-and-conditions">
        Terms & conditions
      </a>
    </div>

    <div className="flex flex-raw justify-center gap-4">
      <SocialIcon name="telegram" link="https://t.me/+7siGL7Uhq9gyNmU1" />
      <SocialIcon name="x" link="https://x.com/Thumba_official" />
      <SocialIcon name="chat" link="https://t.me/ThumbaSupportBot" />
    </div>
  </div>
);
