import { env } from "@/config/env";
import axios from "axios";
import { useEffect, useState } from "react";

interface UserCountRes {
  count: number;
}

export const useUserInvitedCount = (telegramUserId: number | undefined) => {
  const [invitedCount, setInvitedCount] = useState<number>(0);

  useEffect(() => {
    const init = async () => {
      if (!telegramUserId) return;

      const { API_ENDPOINT } = env;
      const res = await axios.get<UserCountRes>(`${API_ENDPOINT}/user/invited?telegramUserId=${telegramUserId}`);
      const { count } = res.data;

      setInvitedCount(count);
    };

    init();
  }, [telegramUserId]);

  return {
    invitedCount,
  };
};
