import { LoadingSpinner } from "@/components/LoadingSpinner";
import { TWA } from "@/components/twa";
import { env } from "@/config/env";
import { useUserCampaign } from "@/hooks/useUserCampaign";
import { SDKProvider, useInitDataRaw, useLaunchParams } from "@telegram-apps/sdk-react";

const Inner: React.FC = () => {
  const initDataRaw = useInitDataRaw();
  const telegramUserId = initDataRaw.result?.user?.id;

  const { userCampaign } = useUserCampaign(telegramUserId);

  if (userCampaign == null || telegramUserId == null) return <LoadingSpinner />;

  return <TWA telegramUserId={telegramUserId} />;
};

export const TwaPage = () => {
  const debug = useLaunchParams().startParam === "debug" || env.DEV;

  return (
    <SDKProvider acceptCustomStyles debug={debug}>
      <Inner />
    </SDKProvider>
  );
};
