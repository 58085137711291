import { SubButton } from "./SubButton";

interface Props {
  closeModal: () => void;
}

export const ImportantNotes: React.FC<Props> = ({ closeModal }) => {
  return (
    <div className="fixed h-screen w-screen flex flex-col inset-0 justify-center item-center z-50 bg-black p-5">
      <h2 className="text-white text-center text-[18px] leading-[35px] mb-4">※Important notes</h2>
      <p className="text-[#989898] text-[12px] leading-[15px] mb-4 max-w-[500px] mx-auto">
        1.Rewards can be received after the service is released by completing the tutorial. Even if you meet the
        participation requirements, you will not be eligible for the lottery if you do not complete the tutorial.
      </p>
      <p className="text-[#989898] text-[12px] leading-[15px] mb-4 max-w-[500px] mx-auto">
        2. The rewards are determined by lottery, not on a first-come, first-served basis, so you may not win.
        Additionally, please note that the amount you win may vary from person to person.
      </p>
      <p className="text-[#989898] text-[12px] leading-[15px] mb-4 max-w-[500px] mx-auto">
        3. Besides participating in the service, there is a possibility of receiving additional rewards by achieving the
        channel subscriber target. Information about these rewards will be gradually revealed as the number of
        subscribers increases.
      </p>

      <SubButton className="mx-auto px-20" onClick={closeModal}>
        Close
      </SubButton>
    </div>
  );
};
