import { cn } from "@/utils";
import { useInView } from "react-intersection-observer";
import { MainButton } from "../shared/MainButton";

export const Community = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    delay: 500,
    triggerOnce: true,
  });

  return (
    <div className="w-full mx-auto mt-[100px]" ref={ref}>
      <p className={cn("text-white text-[29px] leading-normal text-center fadeInUp", inView && "fadeInUp-active")}>
        Community
      </p>

      <div className="w-full">
        <div
          className={cn(
            "relative mt-5 h-[600px] w-full bg-[url('/community/bg-sp.jpg')] hover:bg-[url('/community/bg-sp-hover.jpg')] bg-cover bg-no-repeat bg-top flex flex-col items-center justify-start",
            inView && "fadeInUp-active",
          )}
          style={{ animationDelay: "0.2s" }}
        >
          <p className="text-[49px] leading-normal text-white mt-[80px]">Join our channel!</p>

          <p className="text-white text-[14px]  leading-normal">
            Stay Tuned for Thumba updates, tournaments, and promotions!
          </p>

          <p className="text-white text-[14px] mt-3 pb-0">We're excited to welcome you</p>

          <p className="text-white text-[14px]">to our community!</p>

          <a
            href="https://t.me/ThumbaAnnouncement"
            className={cn("w-5/6 absolute bottom-0 fadeInUp", inView && "fadeInUp-active")}
            style={{ animationDelay: "0.4 s" }}
          >
            <MainButton className="w-full mx-auto">
              <img src="/telegram-black.svg" alt="Play" className="h-5" />
              Join channel
            </MainButton>
          </a>
        </div>
      </div>
    </div>
  );
};
