import { OurPartners, OurTeam } from "../pc/OurInformation";
import { OurRoadmap } from "./OurRoadmap";

export const OurInformation = () => {
  return (
    <div className="w-full bg-[url('/our-information-bg-sp.png')] bg-cover bg-top bg-no-repeat relative">
      <OurRoadmap />

      <OurTeam isMobile={true} />

      <OurPartners />
    </div>
  );
};
