import ReactGA from "react-ga4";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { AmlPolicy } from "./pages/AmlPolicy";
import { EmulateTwaPage } from "./pages/EmulateTwaPage";
import { FAQ } from "./pages/FAQ";
import { HomePage } from "./pages/Home";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { TwaPage } from "./pages/Twa";

const App = () => {
  ReactGA.initialize("G-H5SWYMDGX4");

  return (
    <Router basename="/">
      <Routes>
        <Route path="/">
          <Route index element={<HomePage />} />
          <Route path="/twa" element={<TwaPage />} />
          <Route path="/emulate-twa" element={<EmulateTwaPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/aml-policy" element={<AmlPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/faq" element={<FAQ />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
