import { env } from "@/config/env";
import { MainLinkButton } from "../shared/MainButton";
import { SubButton } from "../shared/SubButton";

interface Props {
  currentStep: 0 | 1 | 2 | 3 | 4; //　現在のステップ数
  step: 1 | 2 | 3 | 4; // ボタンを表示するステップ数
  telegramUserId: number;
  title: string;
  activeChildren: React.ReactNode;
  inactiveChildren: React.ReactNode;
}

export const CampaignButton: React.FC<Props> = (props) => {
  const { currentStep, step, telegramUserId, title, activeChildren, inactiveChildren } = props;
  const { API_ENDPOINT } = env;
  const isFinished = currentStep >= step;
  const isNextStep = currentStep === step - 1;

  const renderTitle = () => (
    <p
      className={`
        ${isNextStep ? "text-gradient" : "text-[#989898]"}
        text-[14px] leading-[18px] pt-4 pb-2
     `}
    >
      <span className={`${isNextStep ? "text-gradient" : "text-[#545454]"}`}>{step}.</span> {title}
    </p>
  );

  const renderButton = () => {
    if (isFinished) {
      return (
        <SubButton className="w-full" height="35px">
          Step{step} Complete
        </SubButton>
      );
    }

    if (!isNextStep) {
      return (
        <SubButton className="w-full" height="35px">
          {inactiveChildren}
        </SubButton>
      );
    }

    return (
      <MainLinkButton
        className="w-full"
        height="39px"
        href={`${API_ENDPOINT}/user/campaign/step${step}?telegramUserId=${telegramUserId}`}
      >
        {activeChildren}
      </MainLinkButton>
    );
  };

  return (
    <>
      {renderTitle()}
      {renderButton()}
    </>
  );
};
