interface Props {
  className?: string;
  width?: string;
  height?: string;
  onClick?: () => void;
  children: React.ReactNode;
  href?: string;
}

export const SubButton: React.FC<Props> = ({ width = "276px", height = "55px", className, onClick, children }) => {
  return (
    <button
      type="button"
      className={`w-[${width}] h-[${height}] text-[16px] text-[#989898] font-bold px-4 py-2 rounded-full bg-[#2A2A2A] flex items-center justify-center gap-2 ${className}`}
      onClick={() => onClick?.()}
    >
      {children}
    </button>
  );
};

export const SubLinkButton: React.FC<Props> = ({ width = "276px", height = "55px", className, children, href }) => {
  return (
    <a
      type="button"
      className={`w-[${width}] h-[${height}] text-[16px] text-[#989898] font-bold px-4 py-2 rounded-full bg-[#2A2A2A] flex items-center justify-center gap-2 ${className}`}
      href={href}
    >
      {children}
    </a>
  );
};
