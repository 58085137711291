import { useUserCount } from "@/hooks/useUserCount";
import { MainButton } from "../shared/MainButton";

interface Props {
  currentKeyVisualIndex: number;
}

export const KeyVisual: React.FC<Props> = ({ currentKeyVisualIndex }) => {
  const keyVisualUrl = `/key-visual-0${currentKeyVisualIndex}.png`;
  const { userCount } = useUserCount();

  return (
    <>
      <p className="text-white text-[28px] leading-[35px] mx-[30px]">
        Enter the thrilling world
        <br />
        of fantasy sports
        <br />
        with just your thumb
      </p>
      <div
        className="bg-cover bg-center bg-no-repeat w-full h-[calc(100vw*560/390)] relative"
        style={{ backgroundImage: `url('${keyVisualUrl}')` }}
      >
        <div className="absolute bottom-[27px] left-[30px]">
          <a href="https://t.me/ThumbaGameBot">
            <MainButton className="w-[250px]">Play Now</MainButton>
          </a>

          <a href="https://t.me/ThumbaAnnouncement">
            <button
              className="text-[#989898] rounded-full text-[16px] leading-[35px] w-40 bg-[#2A2A2A] mt-[10px] hover:bg-gradient-to-r hover:from-[#FFFACD] hover:to-[#AAFFB3] "
              type="button"
              onClick={() => window.open("https://t.me/ThumbaGameBot", "_blank")}
            >
              Announcements
            </button>
          </a>

          {userCount != null && (
            <>
              <p className="text-[#989898] text-[14px] leading-[14px] mt-4 mb-1">Pre-registration users​</p>
              <p className="text-white text-[46px] leading-[46px]">{userCount.toLocaleString()}</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};
