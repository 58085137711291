import { LoadingSpinner } from "@/components/LoadingSpinner";
import { ImportantNotes } from "@/components/shared/ImportantNotes";
import { useUserCampaign } from "@/hooks/useUserCampaign";
import { useState } from "react";
import { Campaign } from "./Campaign";
// import { ExtraCampaign } from "./ExtraCampaign";
import { Reward } from "./Reward";
import { SubCampaign } from "./SubCampaign";

interface Props {
  telegramUserId: number | undefined;
}

export const TWA: React.FC<Props> = ({ telegramUserId }) => {
  const [showImportantNotes, setShowImportantNotes] = useState(false);
  const { userCampaign } = useUserCampaign(telegramUserId);

  if (userCampaign == null || telegramUserId == null) return <LoadingSpinner />;

  return (
    <>
      <Campaign campaign={userCampaign} telegramUserId={telegramUserId} />
      <SubCampaign campaign={userCampaign} telegramUserId={telegramUserId} />
      {/* <ExtraCampaign /> */}
      <Reward openImportantNotes={() => setShowImportantNotes(true)} />
      {showImportantNotes && <ImportantNotes closeModal={() => setShowImportantNotes(false)} />}
    </>
  );
};
