import "swiper/css";
import { cn } from "@/utils";
import { useInView } from "react-intersection-observer";
import { A11y, Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Sport, sports } from "../pc/Sports";

export const Sports = () => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    delay: 500,
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      <div className="w-full h-full text-center mt-10" ref={ref}>
        <p className={cn("text-white text-[24px] leading-[24px] fadeInUp", inView && "fadeInUp-active")}>Sports</p>
      </div>

      <div>
        <Swiper
          modules={[Navigation, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log("Swiper initialized", swiper)}
          className={cn("w-full my-[40px] sports fadeInUp hello", inView && "fadeInUp-active")}
        >
          {sports.map((sport) => (
            <SwiperSlide key={sport.id}>
              <Sport sport={sport} className="w-full p-2" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="w-full flex justify-center">
        <div className="scroll-container">
          <div className="scroll-content">
            {Array.from({ length: 48 }, (_, i) => {
              const num = (i % 16) + 1;
              const formattedNum = num.toString().padStart(3, "0");
              return (
                <img
                  key={`sport-sp-${i}-${formattedNum}`}
                  src={`/sports/${formattedNum}.svg`}
                  alt={formattedNum}
                  className="w-6 h-6 p-4 box-content mx-1 bg-[#0B0B0B] border border-[#313131]"
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
