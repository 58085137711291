import { useEffect, useState } from "react";

import { cn } from "@/utils";
import { useInView } from "react-intersection-observer";
import { MainButton } from "../shared/MainButton";
import { Community } from "./Community";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { HowToPlay } from "./HowToPlay";
import { KeyVisual } from "./KeyVisual";
import { OurInformation } from "./OurInfomation";
import { Sports } from "./Sports";

export const MobileLayout: React.FC = () => {
  const [currentKeyVisualIndex, setCurrentKeyVisualIndex] = useState(1);

  const { ref, inView } = useInView({
    threshold: 0.3,
    delay: 500,
    triggerOnce: true,
  });

  useEffect(() => {
    const toggleInterval = setInterval(() => {
      setCurrentKeyVisualIndex((prev) => (prev === 1 ? 2 : 1));
    }, 1000);

    return () => clearInterval(toggleInterval); // Clean up the interval on unmount
  }, []);

  return (
    <>
      <Header onClick={() => setCurrentKeyVisualIndex((prev) => (prev === 1 ? 2 : 1))} />
      <main className="pt-[132px] pb-[50px] md:hidden block">
        <KeyVisual currentKeyVisualIndex={currentKeyVisualIndex} />

        <HowToPlay />

        <Sports />

        <Community />

        <OurInformation />

        <div
          className={cn("h-full text-center w-[300px] mx-auto pt-10 fadeInUp", inView && "fadeInUp-active")}
          ref={ref}
        >
          <a href="https://t.me/ThumbaGameBot">
            <MainButton className="w-full mx-auto text-[18px]">Play Now</MainButton>
          </a>
        </div>

        <Footer />
      </main>
    </>
  );
};
