import { cn } from "@/utils";
import { useInView } from "react-intersection-observer";
import { MainButton } from "../shared/MainButton";

export const Community = () => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    delay: 500,
    triggerOnce: true,
  });

  return (
    <div className="w-full max-w-[1440px] mx-auto mt-[100px]" ref={ref}>
      <p className={cn("text-white text-[32px] leading-[40px] text-center fadeInUp", inView && "fadeInUp-active")}>
        Community
      </p>

      <div className="max-w-[1440px] w-full">
        <div
          className={cn(
            "mt-5 h-[600px] w-full bg-[url('/community.png')] hover:bg-[url('/community-hover.png')] bg-cover bg-no-repeat bg-top fadeInUp",
            inView && "fadeInUp-active",
          )}
        >
          <div className="px-12 pt-[140px]">
            <p className="text-[128px] leading-[100px] text-white">
              Join our <br />
              channel!
            </p>

            <div className="mt-12">
              <p className="text-white text-[20px] leading-[28px]">
                Stay Tuned for Thumba updates, tournaments, and promotions!
              </p>

              <p className="text-white text-[36px] leading-[28px] mt-3">
                Stay Tuned for Thumba updates, tournaments, and promotions!
              </p>
            </div>
          </div>
        </div>

        <div className="w-full max-w-[1440px] flex justify-center mt-[40px]">
          <a
            href="https://t.me/ThumbaAnnouncement"
            className={cn("w-5/6 fadeInUp", inView && "fadeInUp-active")}
            style={{ animationDelay: "0.2s" }}
          >
            <MainButton className="w-full mx-auto text-[30px]">
              <img src="/telegram-black.svg" alt="Play" className="h-5" />
              Join channel
            </MainButton>
          </a>
        </div>
      </div>
    </div>
  );
};
