import { useUserCount } from "@/hooks/useUserCount";
import { MainButton } from "../shared/MainButton";

type Props = {
  currentKeyVisualIndex: number;
};

export const KeyVisual: React.FC<Props> = ({ currentKeyVisualIndex }: Props) => {
  const keyVisualUrl = `/pc-kv-${currentKeyVisualIndex}.png`;
  const { userCount } = useUserCount();

  return (
    <div className="w-full bg-[url('/pc-mainvisual.png')] bg-cover bg-center bg-no-repeat relative py-3">
      <div className="absolute inset-0 bg-gradient-to-t from-black from-0% via-transparent via-15% from-98%" />

      <div className="max-w-[1040px] mx-auto flex justify-between">
        <div className="my-[90px] z-10">
          <p className="text-white text-[50px] leading-[54px] my-[30px]">
            Enter the thrilling world
            <br />
            of fantasy sports
            <br />
            with just your thumb
          </p>
          <a href="https://t.me/ThumbaGameBot">
            <MainButton className="w-[420px] text-[30px]">Play Now</MainButton>
          </a>

          <a href="https://t.me/ThumbaAnnouncement">
            <button
              className="text-[#989898] hover:text-black rounded-full text-[16px] leading-[35px] w-40 bg-[#2A2A2A] mt-[10px] hover:bg-gradient-to-r hover:from-[#FFFACD] hover:to-[#AAFFB3] "
              type="button"
              onClick={() => window.open("https://t.me/ThumbaGameBot", "_blank")}
            >
              Announcements
            </button>
          </a>

          {userCount != null && (
            <>
              <p className="text-white text-[14px] leading-[14px] mt-4 mb-1">Monthly active users​</p>
              <p className="text-white text-[46px] leading-[46px]">{userCount.toLocaleString()}</p>
            </>
          )}
        </div>
        <img src={keyVisualUrl} alt="KV" className="h-[526px] z-999" />
      </div>
    </div>
  );
};
